













































import { Component, Vue, Watch } from "vue-property-decorator";

import ApplicationConfigValidation from "@/application-manager/components/ApplicationConfigValidation.vue";
import { ApplicationBasicInfo } from "@/application-manager/models/ApplicationBasicInfo";
import { AppSection } from "@/shared/models";

@Component({ components: { ApplicationConfigValidation } })
export default class ApplicationsBasicInfoView extends Vue {
  readonly rows: Array<keyof ApplicationBasicInfo> = [
    "name",
    "platform",
    "organization",
    "urlConfigDistribution",
    "urlDefaultAppConfig",
    "bundleIds",
  ];

  get isLoading(): boolean {
    return false;
  }

  get info(): ApplicationBasicInfo | null {
    return this.$store.state.applicationInfoStore.applicationBasicInfo;
  }

  get isApplicationBasicInfoLoading(): boolean {
    return this.$store.state.applicationInfoStore.isApplicationBasicInfoLoading;
  }

  get appName(): string {
    return this.$store.state.application.appName;
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get hasApplicationConfigValidationAccess(): boolean {
    return this.$store.state.userStore.currentUser.viewAccessEntities[
      this.applicationId
    ].includes(AppSection.APPLICATION_VALIDATION);
  }

  @Watch("applicationId", { immediate: true })
  watchAppId() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.applicationBasicInfo.title", this.appName)
    );

    this.$store.dispatch("fetchApplicationBasicInfo", this.applicationId);
  }
}
