











































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class ApplicationConfigValidation extends Vue {
  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get isApplicationValidationLoading(): boolean {
    return this.$store.state.applicationInfoStore
      .isApplicationValidationLoading;
  }

  get applicationValidationErrors(): Record<string, Array<string>> | null {
    return this.$store.state.applicationInfoStore.applicationValidation;
  }

  get hasErrors(): boolean {
    return this.applicationValidationErrors
      ? !!Object.values(this.applicationValidationErrors).filter(
          (errors) => errors.length
        ).length
      : false;
  }

  get columnCount(): number {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return 1;
    }

    if (this.$vuetify.breakpoint.lg) {
      return 2;
    }

    return 3;
  }

  @Watch("applicationId", { immediate: true })
  watchApp() {
    this.$store.dispatch("validateApplicationConfig", this.applicationId);
  }
}
